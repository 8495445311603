import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { NGFNavItems, navItems } from '../_nav';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
import * as i3 from "@angular/router";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, apollo, router) {
        this.http = http;
        this.apollo = apollo;
        this.router = router;
        this.ngfIds = [
            '5e63897aaa3e878408806a67',
            '65effaa25618076e2c5f49b0',
            '65effab85618076e2c5f49b1'
        ];
        this.userDataNew = new User();
        this.userData = new User();
        this.navItemList = navItems;
        this.navItemListTemp = navItems;
        this.ngfNavItems = NGFNavItems;
        this.onlyUser1 = [1];
        this.onlyUser2 = [2];
        this.onlyUser3 = [3];
        this.onlyUser23 = [2, 3];
        this.onlyUser32 = [3, 2];
        this.user3Special = 'noShow';
        this.idToggle = false;
        this.showImportModal = false;
        this.showDeleteModal = false;
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.token = localStorage.getItem('token');
    }
    Object.defineProperty(AuthenticationService.prototype, "currentUserValue", {
        get: function () {
            // if (this.currentUserSubject.value != null) {
            // console.log('current subject',this.currentUserSubject.value.token) }
            return this.currentUserSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.setModal = function () {
        // console.log(this.showImportModal)
        this.showImportModal = true;
        // console.log(this.showImportModal)
    };
    AuthenticationService.prototype.setDeleteModal = function () {
        // console.log(this.showImportModal)
        this.showDeleteModal = true;
        // console.log(this.showImportModal)
    };
    AuthenticationService.prototype.checkNGFUser = function () {
        // const userSuppier_id = this.getCurrentUserType().supplier_id;
        var email = this.getCurrentUserType().email;
        return email.endsWith('@ngf.co.za');
    };
    AuthenticationService.prototype.getUserNo = function () {
        var usertypeno = this.getCurrentUserType().userTypeNo;
        if (JSON.stringify(this.onlyUser1) === JSON.stringify(usertypeno)) {
            this.myUserType = 'user1';
        }
        else if (JSON.stringify(this.onlyUser2) === JSON.stringify(usertypeno)) {
            this.myUserType = 'user2';
        }
        else if (JSON.stringify(this.onlyUser3) === JSON.stringify(usertypeno)) {
            this.myUserType = 'user3';
        }
        else if (JSON.stringify(this.onlyUser23) === JSON.stringify(usertypeno) || JSON.stringify(this.onlyUser32) === JSON.stringify(usertypeno)) {
            this.myUserType = 'user23';
        }
        if (this.myUserType === 'user3') {
            this.checkSupplierShipments();
        }
        return this.myUserType;
    };
    AuthenticationService.prototype.getNavItem = function () {
        var _this = this;
        if (this.checkNGFUser()) {
            return this.ngfNavItems;
        }
        else {
            this.getUserNo();
            this.navItemList = this.navItemListTemp.filter(function (nav) { return (nav.myUserType === _this.myUserType) || (nav.myUserType === '') || (nav.user3Special === _this.user3Special); });
            return this.navItemList;
        }
    };
    AuthenticationService.prototype.getCurrentUserType = function () {
        var data = JSON.parse(localStorage.getItem('currentUserDetail'));
        if (data) {
            return data.User;
        }
        else {
            //  console.log('tokeninfo:', this.userDataNew);
            return this.userDataNew;
        }
    };
    AuthenticationService.prototype.checkSupplierShipments = function () {
        var data = JSON.parse(localStorage.getItem('currentUserDetail'));
        if (data && data.User.supplier_id && (data.User.supplier_id === "5f35095bb2e1a80026428add") || (data.User.supplier_id === "5fbb9150bd4409001f89da78") || (data.User.supplier_id === "5f8d9aa167ba00002579b419")) {
            this.user3Special = 'user3Special';
        }
    };
    // clean data before submit to gql
    AuthenticationService.prototype.clean = function (obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                delete obj[propName];
            }
        }
    };
    // show and hide id collumn on listings
    AuthenticationService.prototype.toggleId = function () {
        // console.log(this.idToggle);
        if (this.idToggle === true) {
            this.idToggle = false;
            return;
        }
        else if (this.idToggle === false) {
            this.idToggle = true;
        }
        // this.idToggle === true ? this.idToggle === false : this.idToggle === true;
        // console.log(this.idToggle);
        return this.idToggle;
    };
    AuthenticationService.prototype.login = function (email, password) {
        var _this = this;
        var loginGQL = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n            mutation Login($email: String!, $password: String!) {\n                Login(email: $email, password: $password) {\n                    token {\n                        user_id\n                        token\n                    }\n                }\n            }"], ["\n            mutation Login($email: String!, $password: String!) {\n                Login(email: $email, password: $password) {\n                    token {\n                        user_id\n                        token\n                    }\n                }\n            }"])));
        return this.apollo.mutate({
            mutation: loginGQL,
            variables: {
                email: email,
                password: password
            }
        }).pipe(map(function (data) {
            var tokenInfo = data.data;
            // console.log("tokeninfo:",tokenInfo);
            if (tokenInfo && tokenInfo.Login) {
                _this.isLogin = true;
                _this.token = tokenInfo.Login.token.token;
                localStorage.setItem('currentUser', JSON.stringify(tokenInfo.Login.token));
                localStorage.setItem('token', _this.token);
                // console.log("tokeninfo:", this.token);
                // setTimeout( () => {
                //    this.refreshToken();
                // } , 5000);
                _this.currentUserSubject.next(tokenInfo.Login.token);
                // console.log('post login',tokenInfo.Login.token.token)
            }
            return tokenInfo;
        }));
    };
    AuthenticationService.prototype.refreshToken = function () {
        var _this = this;
        // console.log('renewing now');
        var renewGQL = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n                       mutation { Renew {\n                           _id\n                           user_id\n                           token\n                           createdAt\n                           expireAt\n                       }\n                   }"], ["\n                       mutation { Renew {\n                           _id\n                           user_id\n                           token\n                           createdAt\n                           expireAt\n                       }\n                   }"])));
        this.apollo.mutate({
            mutation: renewGQL,
            context: {
                // example of setting the headers with context per operation
                headers: new HttpHeaders().set('x-access-token', this.token)
            }
        }).pipe(map(function (newdata) {
            if (_this.router.url.indexOf('login') > -1) {
                // console.log('goodbye', d);
            }
            else {
                // localStorage.removeItem('currentUser');
                // localStorage.removeItem('token');
                localStorage.setItem('currentUser', JSON.stringify(newdata.data.Renew));
                localStorage.setItem('token', (newdata.data.Renew.token));
                _this.token = newdata.data.Renew.token;
                _this.currentUserSubject.value.token = newdata.data.Renew.token;
                // location.reload();
                // console.log('post', d.data.Renew.token);
                console.log('post renew', _this.token);
                // console.log('post renew subjhect', this.currentUserSubject.value.token);
            }
            // let decoded = jwt_decode(this.token.token);
            // console.log("EXP AFTER", decoded.exp);
        }));
        // console.log('end of if token', this.token);
    };
    AuthenticationService.prototype.getUserDetail = function (userid, token) {
        var d = "query { User (_id: \"" + userid + "\") {\n      _id\n      shop_id\n      supplier { country { shortName } name }\n      supplier_id\n      firstName\n      lastName\n      name\n      email\n      password\n      status\n      userTypeNo } }";
        var userDetailGQL = gql(d);
        // console.log('this.token user', this.token);
        return this.apollo.query({
            query: userDetailGQL,
            fetchPolicy: 'network-only',
            variables: {
                _id: userid
            },
            context: {
                // example of setting the headers with context per operation
                headers: new HttpHeaders().set('x-access-token', token)
            }
        }).pipe(map(function (newdata) {
            var userInfo = newdata;
            // console.log(userInfo);
            localStorage.setItem('currentUserDetail', JSON.stringify(userInfo.data));
            return userInfo;
        }));
    };
    AuthenticationService.prototype.getAllUserData = function (token, UserData, IsAdvanceSearch, IsAllUsers) {
        var allUserGQL = gql;
        if (!IsAdvanceSearch) {
            allUserGQL = gql(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["query {\n        Users(limit: 0) {\n          _id\n          shop_id\n          supplier_id\n          name\n          email\n          password\n          status\n          userTypeNo\n        }\n       }"], ["query {\n        Users(limit: 0) {\n          _id\n          shop_id\n          supplier_id\n          name\n          email\n          password\n          status\n          userTypeNo\n        }\n       }"])));
        }
        else {
            var q = '';
            if (IsAllUsers)
                q = 'query {  Users(limit:0, name:  "' + UserData.name + '",email: "' + UserData.email + '"){  _id        shop_id        supplier_id        name        email        password        status        userTypeNo   }       }';
            else
                q = 'query {  Users(limit:0, name:  "' + UserData.name + '",email: "' + UserData.email + '",status: ' + UserData.status + '){ _id        shop_id        supplier_id        name        email        password        status        userTypeNo   }       }';
            allUserGQL = gql(q);
        }
        return this.apollo.query({
            query: allUserGQL,
            fetchPolicy: 'network-only',
            context: {
                // example of setting the headers with context per operation
                headers: new HttpHeaders().set('x-access-token', token)
            }
        }).pipe(map(function (newdata) {
            var userInfo = newdata;
            // console.log(userInfo);
            localStorage.setItem('totaluser', JSON.stringify(userInfo.data));
            return userInfo.data;
        }));
    };
    AuthenticationService.prototype.getUserWiseDetail = function (userid, token) {
        // console.log('sadfasdfasdfr');
        var d = 'query {User(_id: "' + userid + '") {        _id        shop_id        supplier_id    firstName   lastName       name        email        password        status        userTypeNo          }       }';
        var userDetailGQL = gql(d);
        return this.apollo.query({
            query: userDetailGQL,
            fetchPolicy: 'network-only',
            variables: {
                _id: userid
            },
            context: {
                // example of setting the headers with context per operation
                headers: new HttpHeaders().set('x-access-token', token)
            }
        }).pipe(map(function (newdata) {
            var userInfo = newdata;
            localStorage.setItem('getUserWiseDetail', JSON.stringify(userInfo.data));
            return userInfo;
        }));
    };
    AuthenticationService.prototype.editUserDetail = function (isEdit, data, token) {
        var editUserGQL = gql;
        if (!isEdit) {
            editUserGQL = gql(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n        mutation editUser($id:  ID!,$firstName:String!,$lastName:String!,$email: String!, $status: Boolean!) {\n            editUser(_id: $id, user: {firstName:$firstName,lastName:$lastName,email:$email,status: $status}) {\n                _id\n                firstName\n                lastName\n                name\n                email\n                status\n            }\n        }"], ["\n        mutation editUser($id:  ID!,$firstName:String!,$lastName:String!,$email: String!, $status: Boolean!) {\n            editUser(_id: $id, user: {firstName:$firstName,lastName:$lastName,email:$email,status: $status}) {\n                _id\n                firstName\n                lastName\n                name\n                email\n                status\n            }\n        }"])));
        }
        else {
            editUserGQL = gql(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n      mutation createUser($firstName:String!,$lastName:String!,$email: String!, $password: String!,$userTypeNo:[Int!], $shop_id: String, $supplier_id: String) {\n        createUser(\n          user:{\n          firstName:$firstName,\n          lastName:$lastName,\n          email: $email,\n          password: $password,\n          userTypeNo: $userTypeNo,\n          shop_id: $shop_id,\n          supplier_id: $supplier_id}) {\n          _id\n          shop_id\n          supplier_id\n          name\n          email\n          password\n          status\n          userTypeNo\n          }\n        }"], ["\n      mutation createUser($firstName:String!,$lastName:String!,$email: String!, $password: String!,$userTypeNo:[Int!], $shop_id: String, $supplier_id: String) {\n        createUser(\n          user:{\n          firstName:$firstName,\n          lastName:$lastName,\n          email: $email,\n          password: $password,\n          userTypeNo: $userTypeNo,\n          shop_id: $shop_id,\n          supplier_id: $supplier_id}) {\n          _id\n          shop_id\n          supplier_id\n          name\n          email\n          password\n          status\n          userTypeNo\n          }\n        }"])));
        }
        this.userData = data;
        // this.userData.status= data.status=="true"?Boolean(true): Boolean(false);
        /// COME BACK AND FIX
        // this.userData.userTypeNo = Number(this.userData.userTypeNo);
        return this.apollo.mutate({
            mutation: editUserGQL,
            variables: {
                id: data._id,
                firstName: this.userData.firstName,
                lastName: this.userData.lastName,
                email: this.userData.email,
                status: this.userData.status,
                password: this.userData.password,
                userTypeNo: this.userData.userTypeNo,
                shop_id: this.userData.shop_id,
                supplier_id: this.userData.supplier_id
            },
            context: {
                // example of setting the headers with context per operation
                headers: new HttpHeaders().set('x-access-token', token)
            }
        }).pipe(map(function (data) {
            var editInfo = data.data;
            // console.log(editInfo);
            if (editInfo && editInfo) {
                localStorage.setItem('EditcurrentUser', JSON.stringify(editInfo));
            }
            return editInfo;
        }));
    };
    AuthenticationService.prototype.editCustomerDetail = function (isEdit, name, email, password, userTypeNo, shop_id, token) {
        var editUserGQL = gql;
        if (!isEdit) {
            editUserGQL = gql(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n        mutation editUser($id:  ID!,$name:String!,$email: String!, $status: Boolean!) {\n            editUser(_id: $id, user: {name:$name,email:$email,status: $status}) {\n                _id\n                name\n                email\n                status\n            }\n        }"], ["\n        mutation editUser($id:  ID!,$name:String!,$email: String!, $status: Boolean!) {\n            editUser(_id: $id, user: {name:$name,email:$email,status: $status}) {\n                _id\n                name\n                email\n                status\n            }\n        }"])));
        }
        else {
            editUserGQL = gql(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n      mutation createUser($name:String!,$email: String!, $password: String!,$userTypeNo:[Int!], $shop_id: String, $supplier_id: String) {\n        createUser(\n          user:{\n          name: $name,\n          email: $email,\n          password: $password,\n          userTypeNo: $userTypeNo,\n          shop_id: $shop_id,\n          supplier_id: $supplier_id}) {\n          _id\n          shop_id\n          supplier_id\n          name\n          email\n          password\n          status\n          userTypeNo\n          }\n        }"], ["\n      mutation createUser($name:String!,$email: String!, $password: String!,$userTypeNo:[Int!], $shop_id: String, $supplier_id: String) {\n        createUser(\n          user:{\n          name: $name,\n          email: $email,\n          password: $password,\n          userTypeNo: $userTypeNo,\n          shop_id: $shop_id,\n          supplier_id: $supplier_id}) {\n          _id\n          shop_id\n          supplier_id\n          name\n          email\n          password\n          status\n          userTypeNo\n          }\n        }"])));
        }
        // this.userData = data;
        // this.userData.status= data.status=="true"?Boolean(true): Boolean(false);
        /// COME BACK AND FIX
        // this.userData.userTypeNo = Number(this.userData.userTypeNo);
        return this.apollo.mutate({
            mutation: editUserGQL,
            variables: {
                //  id: data._id,
                name: this.userData.name,
                email: this.userData.email,
                status: this.userData.status,
                password: this.userData.password,
                userTypeNo: this.userData.userTypeNo
            },
            context: {
                // example of setting the headers with context per operation
                headers: new HttpHeaders().set('x-access-token', token)
            }
        }).pipe(map(function (data) {
            var editInfo = data.data;
            // console.log(editInfo);
            if (editInfo && editInfo) {
                localStorage.setItem('EditcurrentUser', JSON.stringify(editInfo));
            }
            return editInfo;
        }));
    };
    AuthenticationService.prototype.logout = function () {
        localStorage.clear();
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        localStorage.removeItem('currentUserDetail');
        this.currentUserSubject.next(null);
        this.isLogin = false;
        // this.router.navigate(['/login']);
        location.reload();
    };
    AuthenticationService.prototype.timeOut = function () {
        localStorage.clear();
        this.currentUserSubject.next(null);
        this.showMsg = true;
        this.isLogin = false;
        this.router.navigate(['/login']);
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.HttpClient), i0.inject(i2.Apollo), i0.inject(i3.Router)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
